// @flow

import { type ConfigurationProvider } from 'src/configuration/ConfigurationProvider';
import { type Environment } from 'src/configuration/Environment';

import { WARN, TRACE, ERROR, INFO } from 'src/utils/logger/LogLevel';

export class LoggerConfigurationProvider implements ConfigurationProvider {
  name(): string {
    return this.constructor.name;
  }

  priority(): number {
    return 1001;
  }

  configurations(): { [Environment]: { [key: string]: any } } {
    return {
      PROD: {
        'logger.consoleLogAppender.enabled': false,
        'logger.inMemoryAppender.enabled': false,
        'logger.consoleLogAppender.level': ERROR
      },
      PREVIEW: {
        'logger.consoleLogAppender.enabled': true,
        'logger.inMemoryAppender.enabled': true,
        'logger.inMemoryAppender.level': WARN
      },
      TEST: {
        'logger.consoleLogAppender.enabled': false,
        'logger.inMemoryAppender.enabled': false
      },
      DEV: {
        'logger.consoleLogAppender.enabled': true,
        'logger.inMemoryAppender.enabled': true,
        'logger.consoleLogAppender.level': INFO,
        'logger.inMemoryAppender.level': TRACE
      }
    };
  }
}
