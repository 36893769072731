// @flow

import { getEnvironmentFromBrowserContext } from 'src/configuration/Environment';

import { type Environment } from 'src/configuration/Environment';
import { type OwnAppVariables } from 'src/configuration/env';

import { OWN_APP_VARIABLES_MAP } from 'src/configuration/env';

// Create React App (built-in)
export const PUBLIC_URL = process.env.PUBLIC_URL;

const _CURRENT_ENVIRONMENT: Environment = getEnvironmentFromBrowserContext();

export const getEnvironment = (): Environment => {
  return _CURRENT_ENVIRONMENT;
};

const _CURRENT_APP_VARIABLES: OwnAppVariables = OWN_APP_VARIABLES_MAP[getEnvironment()];

const getOwnAppVariablesProperty = (varName: $Keys<OwnAppVariables>): string => {
  return _CURRENT_APP_VARIABLES[varName];
};
// Custom (core)
export const ENVIRONMENT: string = getOwnAppVariablesProperty('ENVIRONMENT');

// Custom (monANCAI)
export const PUBLIC_STATIC_URL: string = getOwnAppVariablesProperty('PUBLIC_STATIC_URL');
export const API_URL: string = getOwnAppVariablesProperty('API_URL');
export const REALTIME_URL: string = getOwnAppVariablesProperty('REALTIME_URL');
export const GOOGLE_MAPS_API: string = getOwnAppVariablesProperty('GOOGLE_MAPS_API');
const _INACTIVITY_TIMEOUT: string = getOwnAppVariablesProperty('INACTIVITY_TIMEOUT');
export const INACTIVITY_TIMEOUT: number = parseInt(_INACTIVITY_TIMEOUT, 10);
export const ROOT_LOGGER_LEVEL: string = getOwnAppVariablesProperty('ROOT_LOGGER_LEVEL');
export const VERSION = process.env.REACT_APP_VERSION;
export const NAME = process.env.REACT_APP_NAME;

// Custom (test only)
export const TEST_USERNAME = process.env.ANCAI_TEST_USERNAME;
export const TEST_PASSWORD = process.env.ANCAI_TEST_PASSWORD;
