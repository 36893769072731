// @flow

import { startOfInceptionYear } from 'src/utils/DateUtils';

import { type ConfigurationProvider } from 'src/configuration/ConfigurationProvider';
import { type Environment } from 'src/configuration/Environment';

export const CONFIGURATION_MIN_DATE = 'date.minDate';

export class FormConfigurationProvider implements ConfigurationProvider {
  name(): string {
    return 'FORM';
  }

  priority(): number {
    return 1001;
  }

  configurations(): { [Environment]: { [key: string]: any } } {
    return {
      PROD: {
        [CONFIGURATION_MIN_DATE]: startOfInceptionYear()
      },
      PREVIEW: {
        [CONFIGURATION_MIN_DATE]: startOfInceptionYear()
      },
      TEST: {
        [CONFIGURATION_MIN_DATE]: startOfInceptionYear()
      },
      DEV: {
        [CONFIGURATION_MIN_DATE]: startOfInceptionYear()
      }
    };
  }
}
