// @flow
import React from 'react';
import { isNodeEnvDevelopment } from 'src/core/configuration/NodeEnvUtils';

const useWhyDidYouRender = isNodeEnvDevelopment();
if (useWhyDidYouRender) {
  const whyDidYouRender = require('@welldone-software/why-did-you-render');
  whyDidYouRender(React, {
    trackAllPureComponents: false
  });
}
