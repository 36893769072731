// @flow
import _set from 'lodash/set';
import _get from 'lodash/get';

export const DEFAULT_NAMESPACE = 'monancai';

export const setVariable = <T: any>(key: string, value: T): void => {
  if (window == null) {
    // window is not available
    // do nothing
    return;
  }
  _set(window, getKey(key), value);
};

export const getVariable = <T: any>(key: string): T | null => {
  if (window == null) {
    // window is not available
    // return null
    return null;
  }
  return _get(window, getKey(key));
};

const getKey = (key: string): string => {
  return `${DEFAULT_NAMESPACE}.${key}`;
};
