// @flow
import { preload } from 'src/core/preloader/AppPreloaderUtil';

// make sure to import each file in the same order of the initializer
import { RequiredProcessEnvVarsValidator } from 'src/configuration/ProcessEnvValidator';
import { BaseLoggerFactoryAppPreloader } from 'src/utils/logger/BaseLoggerFactoryAppPreloader';

import { MonAncaiConfigurationServiceAppPreloader } from 'src/modules/configuration/MonAncaiConfigurationServiceAppPreloader';
import { InteractionObserverPolyfillAppPreloader } from 'src/polyfill/InteractionObserverPolyfillAppPreloader';
import { URLSearchParamsSortPolyfillAppPreloader } from 'src/polyfill/URLSearchParamsSortPolyfillAppPreloader';

export const preloadMonAncaiApp = (): void => {
  preload([
    new RequiredProcessEnvVarsValidator(),
    new MonAncaiConfigurationServiceAppPreloader(),
    new BaseLoggerFactoryAppPreloader(),
    new InteractionObserverPolyfillAppPreloader(),
    new URLSearchParamsSortPolyfillAppPreloader()
  ]);
};
