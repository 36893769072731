// @flow

const logLevels = {
  TRACE: 'TRACE',
  DEBUG: 'DEBUG',
  INFO: 'INFO',
  WARN: 'WARN',
  ERROR: 'ERROR'
};
export type LogLevel = $Keys<typeof logLevels>;

export const logLevelFromValue = (value: string): LogLevel | void => {
  return logLevels[value];
};

export const TRACE: LogLevel = 'TRACE';
export const DEBUG: LogLevel = 'DEBUG';
export const INFO: LogLevel = 'INFO';
export const WARN: LogLevel = 'WARN';
export const ERROR: LogLevel = 'ERROR';

export const getLogLevelPriority = (logLevel: LogLevel): number => {
  switch (logLevel) {
    case TRACE:
      return 0;
    case DEBUG:
      return 1;
    case INFO:
      return 2;
    case WARN:
      return 3;
    case ERROR:
      return 4;
    default:
      throw new RangeError(`Invalid LogLevel '${logLevel}'`);
  }
};

export const isLogLevelActive = (logToCheck: LogLevel, logLevelRef: LogLevel): boolean => {
  return getLogLevelPriority(logToCheck) >= getLogLevelPriority(logLevelRef);
};
