// @flow
import * as React from 'react';

import { addClassName } from 'src/utils/domUtils';
import { concatClassNames } from 'src/utils/stringUtils';
import { getBEMModifiers } from 'src/utils/stringUtils';
import { removeClassName } from 'src/utils/domUtils';

import { type ProgressObserver } from 'src/modules/apiUtils/ProgressObserver';

import { Loader } from 'src/modules/common/loading/Loader';
import { ProgressLoading } from 'src/modules/apiUtils/ProgressLoading';

type loadingContainerProps = {|
  isPending: boolean,
  fieldRound?: boolean,
  cardRound?: boolean,
  groupRound?: boolean,
  full?: boolean,
  small?: boolean,
  withBg?: boolean,
  noRelative?: boolean, // Not the best, to fix an safari bug, should be improved...
  hideOnPending?: boolean,
  observer?: ProgressObserver,
  children?: React.Node,
  className?: string
|};

export const LoadingContainer = (props: loadingContainerProps): React.Node => {
  const {
    isPending = false,
    fieldRound = false,
    cardRound = false,
    groupRound = false,
    children = null,
    hideOnPending,
    full = false,
    small = false,
    withBg = true,
    noRelative = false,
    observer
  } = props;

  const $ref = React.useRef<HTMLDivElement | null>(null);

  React.useLayoutEffect(() => {
    const ref = $ref.current;
    if (isPending) {
      addClassName(ref, 'cursor--wait');
    } else {
      removeClassName(ref, 'cursor--wait');
    }
    return () => removeClassName(ref, 'cursor--wait');
  }, [isPending, observer]);

  return (
    <div
      ref={$ref}
      className={concatClassNames(
        getBEMModifiers('loadingContainer', { isPending, full, noRelative, small }),
        props.className
      )}
    >
      {!!observer && isPending && <ProgressLoading observer={observer} />}
      {hideOnPending && isPending ? null : children}
      {isPending ? <Loader field={fieldRound} card={cardRound} group={groupRound} withBg={withBg} /> : null}
    </div>
  );
};
