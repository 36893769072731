// @flow

import { BaseConfigurationServiceAppPreloader } from 'src/core/configuration/BaseConfigurationServiceAppPreloader';
import { type ConfigurationProvider } from 'src/configuration/ConfigurationProvider';

import { LoggerConfigurationProvider } from 'src/utils/logger/LoggerConfigurationProvider';
import { RealtimeConfigurationProvider } from 'src/modules/realtime/RealtimeConfigurationProvider';
import { DefaultConfigurationProvider } from 'src/configuration/DefaultConfigurationProvider';
import { FormConfigurationProvider } from 'src/modules/formik/FormConfigurationProvider';

export class MonAncaiConfigurationServiceAppPreloader extends BaseConfigurationServiceAppPreloader {
  configurationProviders(): () => Array<ConfigurationProvider> {
    return () => [
      new DefaultConfigurationProvider(),
      new LoggerConfigurationProvider(),
      new RealtimeConfigurationProvider(),
      new FormConfigurationProvider()
    ];
  }
}
