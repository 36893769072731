// @flow

import { type LoggerFactoryAppPreloader } from 'src/utils/logger/LoggerFactoryAppPreloader';
import { setInstance, LoggerFactory } from 'src/utils/logger/LoggerFactory';

export class BaseLoggerFactoryAppPreloader implements LoggerFactoryAppPreloader {
  loggerFactory(): () => LoggerFactory {
    return () => new LoggerFactory();
  }

  initialize(): void {
    const loggerFactory = this.loggerFactory()();

    loggerFactory.initialize();
    setInstance(loggerFactory);
  }

  name(): string {
    return this.constructor.name;
  }

  priority(): number {
    return 0;
  }

  isDisabled(): boolean {
    return false;
  }
}
