// @flow

export const NAVBAR_ID = 'navbar';
export const ENVIRONMENT_ALERT_ID = 'environmentAlert';

export const APP_PLACEHOLDER_DOM_NODE_ID = 'placeholder';
export const APP_HEADER_ID = 'app-header';
export const APP_MODAL_DOM_NODE_ID = 'app-modal';
export const APP_DRAWER_DOM_NODE_ID = 'app-drawer';
export const FOOTER_NODE_ID = 'app-footer';
export const LAYOUT_NODE_ID = 'layout';

export const CONTENT_HEADER_FILTER_BUTTON_ID = 'content-header-filter-button';
