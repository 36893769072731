// @flow
import { ConsoleLogAppender } from 'src/utils/logger/ConsoleLogAppender';
import { InMemoryLogAppender } from 'src/utils/logger/InMemoryLogAppender';
import { environmentFromValue } from 'src/configuration/Environment';
import { type LogAppender } from 'src/utils/logger/LogAppender';
import { Logger } from 'src/utils/logger/Logger';
import { logLevelFromValue } from 'src/utils/logger/LogLevel';
import { getInstance as getConfigurationService } from 'src/configuration/ConfigurationService';
import { getEnvironment } from 'src/configuration/ReactAppConfigurationProvider';
import { type Preloadable } from 'src/core/preloader/Preloadable';
import { requireNonNil } from 'src/utils/ObjectUtils';
import { setVariable as setWindowVariable } from 'src/core/browser/WindowUtil';

import { type Environment } from 'src/configuration/Environment';
import { type LogLevel } from 'src/utils/logger/LogLevel';

import { Environments_ } from 'src/configuration/Environment';
import { ROOT_LOGGER_LEVEL } from 'src/configuration/ReactAppConfigurationProvider';
import { TRACE } from 'src/utils/logger/LogLevel';

const DEFAULT_ROOT_LOGGER_LEVEL: LogLevel = TRACE;

export class LoggerFactory implements Preloadable {
  loggers: { [key: string]: Logger } = {};
  appenders: LogAppender[] = [];
  rootLogLevel: LogLevel = DEFAULT_ROOT_LOGGER_LEVEL; // TODO remove this (accomodate tests for now)

  name(): string {
    return this.constructor.name;
  }

  priority(): number {
    return 0;
  }

  isDisabled(): boolean {
    return false;
  }

  initialize(): void {
    this.rootLogLevel = requireNonNil(logLevelFromValue(ROOT_LOGGER_LEVEL ?? DEFAULT_ROOT_LOGGER_LEVEL));
    const consoleLogAppenderEnabled: boolean = getConfigurationService().get('logger.consoleLogAppender.enabled');
    const consoleLogAppenderLevel: LogLevel = getConfigurationService().get('logger.consoleLogAppender.level');
    consoleLogAppenderEnabled && this.addAppenders(new ConsoleLogAppender(consoleLogAppenderLevel));

    const inMemoryAppenderEnabled: boolean = getConfigurationService().get('logger.inMemoryAppender.enabled');
    const inMemoryAppenderLevel: LogLevel = getConfigurationService().get('logger.inMemoryAppender.level');
    inMemoryAppenderEnabled && this.addAppenders(new InMemoryLogAppender(inMemoryAppenderLevel));

    const currentEnvironment: Environment = requireNonNil(environmentFromValue(getEnvironment()));

    if (currentEnvironment !== Environments_.PROD) {
      setWindowVariable('loggerFactory', this);
    }
  }

  addAppenders(logAppender: LogAppender): void {
    this.appenders.push(logAppender);
  }

  setLoggersLevel(level: LogLevel): void {
    Object.keys(this.loggers).forEach((property) => {
      this.loggers[property].setLevel(level);
    });
  }

  getLogger(name: string): Logger {
    let logger: Logger = this.loggers[name];
    if (logger == null) {
      logger = new Logger(name, this.rootLogLevel, this.appenders);
      this.loggers[name] = logger;
    }
    return logger;
  }
}

// TODO remove and favor using using getInstance as getLoggerFactory
export let loggerFactory: LoggerFactory = new LoggerFactory();
let _loggerFactory: LoggerFactory;

export const getInstance = (): LoggerFactory => {
  if (_loggerFactory == null) {
    // TODO remove this (accomodate tests for now)
    return loggerFactory;
  }
  return _loggerFactory;
};

// TODO rename to loggerFactory after getInstance is in place
export const setInstance = (loggerFactory2: LoggerFactory): void => {
  if (_loggerFactory != null) {
    throw new Error('configuration service is already set');
  }
  _loggerFactory = loggerFactory2;
  loggerFactory = loggerFactory2;
};
