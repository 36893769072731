// @flow
import { ENVIRONMENT_DEV_DOMAIN } from 'src/configuration/EnvironmentConstants';
import { ENVIRONMENT_PREVIEW_DOMAIN } from 'src/configuration/EnvironmentConstants';

export const Environments_ = Object.freeze({
  DEV: 'DEV',
  TEST: 'TEST',
  PREVIEW: 'PREVIEW',
  PROD: 'PROD'
});
export type Environment = $Keys<typeof Environments_>;

export const environmentFromValue = (value: string): Environment | void => {
  return Environments_[value];
};

export const ENVIRONMENT_PRIORITIES: Array<Environment> = [
  Environments_.PROD,
  Environments_.PREVIEW,
  Environments_.TEST,
  Environments_.DEV
];

// Maybe TODO, add includes "test" for test?
export const getEnvironmentFromBrowserContext = (): Environment => {
  if (window.location.host.includes(ENVIRONMENT_DEV_DOMAIN)) {
    return Environments_.DEV;
  }
  if (window.location.host.includes(ENVIRONMENT_PREVIEW_DOMAIN)) {
    return Environments_.PREVIEW;
  }
  return Environments_.PROD;
};
