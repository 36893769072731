// @flow

import { type LogEntry } from 'src/utils/logger/LogEntry';
import { type LogLevel } from 'src/utils/logger/LogLevel';
import { isLogLevelActive } from 'src/utils/logger/LogLevel';

export interface LogAppender {
  level: LogLevel; // TODO remove variable from interface
  append(logEntry: LogEntry): void;
}

// TODO extract in another file
export class BaseLogAppender implements LogAppender {
  level: LogLevel;

  constructor(level: LogLevel) {
    this.level = level;
  }

  get logLevel(): LogLevel {
    return this.level;
  }

  append(logEntry: LogEntry): void {
    if (isLogLevelActive(logEntry.level, this.logLevel)) {
      switch (logEntry.level) {
        case 'INFO':
          this.info(logEntry);
          break;
        case 'WARN':
          this.warn(logEntry);
          break;
        case 'ERROR':
          this.error(logEntry);
          break;
        case 'TRACE':
          this.trace(logEntry);
          break;
        case 'DEBUG':
          this.debug(logEntry);
          break;
        default:
          throw new RangeError(`Invalid LogLevel '${logEntry.level}'`);
      }
    }
  }

  trace(logEntry: LogEntry): void {
    throw new Error('Implement method');
  }

  debug(logEntry: LogEntry): void {
    throw new Error('Implement method');
  }

  info(logEntry: LogEntry): void {
    throw new Error('Implement method');
  }

  warn(logEntry: LogEntry): void {
    throw new Error('Implement method');
  }

  error(logEntry: LogEntry): void {
    throw new Error('Implement method');
  }
}
