// @flow

export type nodeNameEnum = 'DIV' | 'A' | 'BUTTON';

export const DIV_NODE_NAME = 'DIV';
export const A_NODE_NAME = 'A';
export const BUTTON_NODE_NAME = 'BUTTON';

export const domNodeIs = (domNode: HTMLElement, names: Array<nodeNameEnum>): boolean => {
  return names.some((name) => domNode.nodeName === name);
};

export const domNodeIsAnchor = (domNode: HTMLElement): boolean => domNodeIs(domNode, [A_NODE_NAME]);

export const domNodeHasClass = (domNode: HTMLElement, className: string): boolean =>
  domNode.className.includes(className);

export const hasHTMLElement = (
  elements: Iterator<HTMLElement>,
  predicate: (element: HTMLElement) => boolean
): boolean => {
  return Array.from(elements).some(predicate);
};

export const resizeHeightToFitContent = (element: HTMLElement | null): void => {
  if (element !== null) {
    element.style.height = '';
    element.style.height = `${element.scrollHeight}px`;
  }
};

export const isInput = (element: HTMLElement | null): boolean => {
  return element?.tagName === 'INPUT' ?? false;
};

export const addClassName = (element: HTMLElement | null | void, className: string): void => {
  element?.classList?.add(className);
};

export const removeClassName = (element: HTMLElement | null | void, className: string): void => {
  element?.classList?.remove(className);
};

type ScrollIntoViewOptions = {
  behavior?: 'auto' | 'instant' | 'smooth',
  block?: 'start' | 'center' | 'end' | 'nearest',
  inline?: 'start' | 'center' | 'end' | 'nearest',
  ...
};

const DEFAULT_SCROLL_INTO_VIEW_OPTIONS: ScrollIntoViewOptions = {
  behavior: 'smooth'
};

export const maybeScrollIntoView = (
  element: HTMLElement | string | null | void,
  options?: ScrollIntoViewOptions = DEFAULT_SCROLL_INTO_VIEW_OPTIONS
): void => {
  const _element = typeof element === 'string' ? document.getElementById(element) : element;
  _element?.scrollIntoView(options);
};
