// @flow

import { type LogAppender } from 'src/utils/logger/LogAppender';
import { type LogLevel } from 'src/utils/logger/LogLevel';
import { type LogEntry } from 'src/utils/logger/LogEntry';
import { isLogLevelActive } from 'src/utils/logger/LogLevel';

export class Logger {
  // TODO _...
  name: string;
  level: LogLevel;
  appenders: LogAppender[];

  constructor(name: string, level: LogLevel, appenders: LogAppender[]) {
    this.name = name;
    this.level = level;
    this.appenders = appenders;
  }

  trace(message: string, ...extra: Array<any>): void {
    if (this.isTraceEnabled()) {
      this.append('TRACE', message, extra);
    }
  }

  isTraceEnabled(): boolean {
    return isLogLevelActive('TRACE', this.level);
  }

  debug(message: string, ...extra: Array<any>): void {
    if (this.isDebugEnabled()) {
      this.append('DEBUG', message, extra);
    }
  }

  isDebugEnabled(): boolean {
    return isLogLevelActive('DEBUG', this.level);
  }

  info(message: string, ...extra: Array<any>): void {
    if (this.isInfoEnabled()) {
      this.append('INFO', message, extra);
    }
  }

  isInfoEnabled(): boolean {
    return isLogLevelActive('INFO', this.level);
  }

  warn(message: string, ...extra: Array<any>): void {
    if (this.isWarnEnabled()) {
      this.append('WARN', message, extra);
    }
  }

  isWarnEnabled(): boolean {
    return isLogLevelActive('WARN', this.level);
  }

  error(message: string, ...extra: Array<any>): void {
    if (this.isErrorEnabled()) {
      this.append('ERROR', message, extra);
    }
  }

  isErrorEnabled(): boolean {
    return isLogLevelActive('ERROR', this.level);
  }

  setLevel(logLevel: LogLevel): void {
    this.level = logLevel;
  }

  append(logLevel: LogLevel, message: string, extra: Array<any>): void {
    const logEntry: LogEntry = this.newLogEntry(logLevel, message, extra);

    // TODO try/catch
    this.appenders.forEach((appender) => appender.append(logEntry));
  }

  newLogEntry(logLevel: LogLevel, message: string, extra: Array<any>): LogEntry {
    return {
      timestamp: new Date(), // TODO need indirection to clock to allow mocks
      level: logLevel,
      logger: this.name,
      message: message,
      extra: extra
    };
  }
}
