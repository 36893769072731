// @flow
import { type LogFormatter } from 'src/utils/logger/LogFormatter';
import { type LogEntry } from 'src/utils/logger/LogEntry';

export class DefaultLogFormatter implements LogFormatter {
  format(entry: LogEntry): string {
    return `${entry.timestamp.toString()} ${entry.level} [${entry.logger}] %c${entry.message}`;
  }
}
// TODO keep singleton of DefaultLogFormatter
