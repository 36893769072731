// @flow

import { BaseLogAppender } from 'src/utils/logger/LogAppender';
import { type LogFormatter } from 'src/utils/logger/LogFormatter';
import { DefaultLogFormatter } from 'src/utils/logger/DefaultLogFormatter';
import { type LogEntry } from 'src/utils/logger/LogEntry';

const DEFAULT_TRACE_STYLE = 'font-weight: bold';
const DEFAULT_DEBUG_STYLE = 'font-weight: bold';
const DEFAULT_INFO_STYLE = 'color: #004085';
const DEFAULT_WARN_STYLE = 'font-weight: bold';
const DEFAULT_ERROR_STYLE = 'font-weight: bold';

export class ConsoleLogAppender extends BaseLogAppender {
  // TODO method to set log formatter
  // TODO use configuration to get styles
  _logFormatter: LogFormatter = new DefaultLogFormatter(); // TODO initialize with singleton
  _traceStyle: string = DEFAULT_TRACE_STYLE;
  _debugStyle: string = DEFAULT_DEBUG_STYLE;
  _infoStyle: string = DEFAULT_INFO_STYLE;
  _warnStyle: string = DEFAULT_WARN_STYLE;
  _errorStyle: string = DEFAULT_ERROR_STYLE;

  trace(logEntry: LogEntry) {
    // eslint-disable-next-line no-console
    console.log(this._format(logEntry), this._traceStyle, ...logEntry.extra);
  }

  debug(logEntry: LogEntry) {
    // eslint-disable-next-line no-console
    console.log(this._format(logEntry), this._debugStyle, ...logEntry.extra);
  }

  info(logEntry: LogEntry) {
    // eslint-disable-next-line no-console
    console.log(this._format(logEntry), this._infoStyle, ...logEntry.extra);
  }

  warn(logEntry: LogEntry) {
    // eslint-disable-next-line no-console
    console.warn(this._format(logEntry), this._warnStyle, ...logEntry.extra);
  }

  error(logEntry: LogEntry) {
    // eslint-disable-next-line no-console
    console.error(this._format(logEntry), this._errorStyle, ...logEntry.extra);
  }

  _format(logEntry: LogEntry): string {
    return this._logFormatter.format(logEntry);
  }
}
