// @flow
import numeral from 'numeral';
import { isEmpty } from 'lodash';
import { isEqual } from 'lodash';
import { fill as _fill } from 'lodash';
import { get as _get } from 'lodash';
import { groupBy } from 'lodash';
import { isPlainObject as _isPlainObject } from 'lodash';
import { nonNil } from 'src/utils/StreamUtils';
import { objectEntries } from 'src/utils/ObjectUtils';

export const reduceFilterNonNil = <M: any>(acc: Array<M>, current: M | null | void): Array<M> => {
  if (current != null) {
    return [...acc, current];
  }
  return acc;
};

export const arrayOfLength = <T: any>(length: number): Array<T> => {
  // $FlowExpectedError - apply definition incomplete ?
  return Array.apply(null, { length });
};

export const arrayFrom = <T: any>(
  lengthOrArrayLike: number | Object,
  fill: (value: any, index: number) => T
): Array<T> => {
  const _from = typeof lengthOrArrayLike === 'number' ? { length: lengthOrArrayLike } : lengthOrArrayLike;
  return Array.from(_from, fill);
};

export const filterNonNil = <M: any>(list: Array<M | null | void>): Array<M> => {
  return (list.filter(nonNil): any);
};

export const alwaysArray = <T: any>(param: T | Array<T>): Array<T> => (Array.isArray(param) ? param : [param]);

export const createSameValueChunks = <T: any>(values: Array<T>): Array<Array<T>> => {
  return objectEntries(groupBy<T, T>(values, (a: T) => a)).map(([key, groupValue]: [T, Array<T>]) => groupValue);
};

export const generateBooleanArrayTrueForEachFirstOccurance = (values: Array<number>): Array<boolean> => {
  const chunks = createSameValueChunks<number>(values);
  const result = [];
  let resultIndex = 0;

  for (const chunk of chunks) {
    for (let i = 0; i < chunk.length; i++) {
      const value: boolean = i === 0;
      result[resultIndex++] = value;
    }
  }

  return result;
};

const DEFAULT_EMPTY_LIST: Array<any> = Object.freeze([]);

export const defaultListIfEmpty = <T: any>(list: Array<T>, defaultList: Array<T> = DEFAULT_EMPTY_LIST): Array<T> => {
  return isEmpty(list) ? defaultList : list;
};

export const getFromStringCode = <T: any>(collection: Array<T>, string: string, def: T): T => {
  if (collection.length === 0) {
    return def;
  }

  return collection[
    Array.from(string).reduce((prev: number, current: string) => {
      return prev + current.charCodeAt(0);
    }, 0) % collection.length
  ];
};

export const addOrRemove = <T: any>(collection: Array<T>, element: T): Array<T> => {
  let newCollection = collection;
  if (collection.includes(element)) {
    newCollection.splice(
      collection.findIndex(el => el === element),
      1
    );
  } else {
    newCollection = [...newCollection, element];
  }
  return newCollection;
};

export const equals = <T: any>(first: Array<T>, second: Array<T>): boolean => {
  const result = first.sort().join('') === second.sort().join('');
  return result;
};

export const getFirstDifference = <T: any>(oldCollection: Array<T>, newCollection: Array<T>): [number, T | void] => {
  for (let i = 0; i < newCollection.length; i++) {
    if (!isEqual(newCollection[i], oldCollection[i])) {
      return [i, newCollection[i]];
    }
  }

  return [-1, undefined];
};

export const addIfDefined = <T: any>(collection: Array<T>, element: T | null | void): void => {
  if (element != null) {
    collection.push(element);
  }
};

export const addIfNotPresent = <T: any>(collection: Array<T>, element: T): void => {
  if (!collection.includes(element)) {
    collection.push(element);
  }
};

export const padFill = <T: any>(array: Array<T>, value: T, wantedLength: number): Array<T> => {
  return _fill<T, T>(array, value, array.length, wantedLength);
};

export const convertToMapBy = <T: any>(
  array: Array<T> | null | void,
  getKey: (element: T) => string,
  mapValue?: (element: T) => any
): { [key: string]: any } => {
  const _array: Array<T> = array || [];

  const _getKey = (element: T, key: string): string => {
    return _isPlainObject(element) ? _get(element, key) : key;
  };

  return _array.reduce((acc: { [key: string]: any }, element: T) => {
    const _value: any = !!mapValue ? mapValue(element) : element;
    acc[_getKey(element, getKey(element)) || 'error'] = _value;

    return acc;
  }, {});
};

export const convertToMapTransformBy = <T: any, R: any>(
  array: Array<T>,
  getKey: (element: T) => string,
  mapValue: (element: T) => R
): { [key: string]: R } => {
  return convertToMapBy<T>(array, getKey, mapValue);
};

export const filterMaybeElementCollection = <T: any>(collection: Array<T | null | void>): Array<T> => {
  return collection.filter(nonNil).map((e: any) => (e: T));
};

export const reduceInsertItemBetweenElements = (
  itemToInsertBetweenEach: any | ((index: number) => any)
): ((Array<any>, any, number) => Array<any>) => (acc: Array<any>, node: any, index: number): Array<any> => {
  const item = typeof itemToInsertBetweenEach === 'function' ? itemToInsertBetweenEach(index) : itemToInsertBetweenEach;
  return index === 0 ? acc.concat(node) : acc.concat(item, node);
};

export const sum = <T>(list: Array<T>, predicate: (item: T) => number): number => {
  return list.reduce((acc: number, item: T) => (acc += predicate(item)), 0);
};

export const reorderList = <T>(list: Array<T>, startIndex: number, endIndex: number): Array<T> => {
  const result: Array<T> = Array.from(list);
  const [removed]: Array<T> = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

export const chunk = <T>(list: Array<T>, chunkSize: number): Array<Array<T>> => {
  return list.reduce((result: Array<Array<T>>, item: T, index: number) => {
    return index % chunkSize === 0 ? [...result, [item]] : [...result.slice(0, -1), [...result.slice(-1)[0], item]];
  }, ([]: Array<any>));
};

export const groupByPath = (path: string): (<T>(Array<T>) => { [key: string]: Array<T> }) => <T>(
  list: Array<T>
): { [key: string]: Array<T> } => {
  return groupBy(list, path);
};

export const hasDescendingConsecutiveValues = (array: Array<number>): boolean => {
  return array.some((value: number, index: number, array: Array<number>) => {
    if (array.length === index + 1) {
      return false;
    }

    const nextValue = array[index + 1];
    return value > nextValue;
  });
};

export const accrue = <T>(
  array: Array<T>,
  iteratee: T => number | null | void,
  modifier?: (val: number) => number
): number => {
  const reducedResult: Numeral = array.reduce((acc: Numeral, item: any) => {
    const resultForItem: Numeral = acc.add(iteratee(item) ?? 0);
    return resultForItem;
  }, numeral(0));

  return modifier?.(reducedResult.value()) ?? reducedResult.value();
};
