// @flow
import './wdyr'; // Why Did You Render imported at first like asked by the lib
// Vendors
import React, { Suspense, lazy } from 'react';
import * as ReactDOMClient from 'react-dom/client';

import { preloadMonAncaiApp } from 'src/modules/preloader/MonAncaiAppPreloader';
import { Preloader } from 'src/core/preloader/PreloaderComponent';

import { APP_PLACEHOLDER_DOM_NODE_ID } from 'src/NodeConst';

import { registerLocale, setDefaultLocale } from 'react-datepicker';
import frCA from 'date-fns/locale/fr-CA';

registerLocale('fr', frCA);
setDefaultLocale('fr');

preloadMonAncaiApp();

// Load the rest of the app after preloading it
const AsyncApp = lazy(() => import('src/AsyncApp'));

const $Node = document.getElementById(APP_PLACEHOLDER_DOM_NODE_ID);

// Creates problems with used libraries :
// react-google-maps
const useStrictMode = false;

const AppComponent = () => (
  <Suspense fallback={<Preloader />}>
    <AsyncApp />
  </Suspense>
);

const StrictAppComponent = () => (
  <React.StrictMode>
    <AppComponent />
  </React.StrictMode>
);

const ActualAppComponent = useStrictMode ? StrictAppComponent : AppComponent;
const root = $Node && ReactDOMClient.createRoot($Node);

!!root && root.render(<ActualAppComponent />);
