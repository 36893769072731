// @flow
import { objectEntries } from 'src/utils/ObjectUtils';
import { objectValues } from 'src/utils/ObjectUtils';
import { filterMaybeElementCollection } from 'src/utils/ArrayUtils';

export const isURL = (text: string): boolean => {
  return new RegExp('^https?').test(text);
};

export const getPlural = (count: number, plural?: string = 's'): string => (count > 1 ? plural : '');

const HASHID_VALID_STRUCTURE_REGEX = /^[a-zA-Z0-9]{27,28}$/gm;
const HASHID_SANITIZE_REGEX = /[^a-zA-Z0-9]/g;

export const isHashIdCandidate = (maybeHashIdCandidate: string | null | void): boolean => {
  if (!maybeHashIdCandidate) {
    return false;
  }

  return maybeHashIdCandidate.search(HASHID_VALID_STRUCTURE_REGEX) !== -1;
};

export const sanitizeHashIdCanditate = (maybeHashIdCandidate: string | null | void): string => {
  if (!maybeHashIdCandidate) {
    return '';
  }

  return maybeHashIdCandidate.replace(HASHID_SANITIZE_REGEX, '');
};

export const addPathSlashEnd = (path: string): string => {
  if (path[path.length - 1] === '/') {
    return path;
  }
  return `${path}/`;
};

export const removePathSlashEnd = (path: string): string => {
  return path.replace(new RegExp('/$'), '');
};

type ShortNameConfig = {|
  length: number,
  separator: string
|};

export const getShortName = (name: string, config: ShortNameConfig): string => {
  if (!name) {
    return '';
  }
  const { length, separator } = config;

  if (name.includes(separator)) {
    const parts: Array<string> = name.split(separator);
    const charsOnLastPart: number = length - parts.length + 1;

    return parts.reduce((acc: string, part: string, i: number) => {
      if (i === parts.length - 1) {
        acc = `${acc}${part.slice(0, charsOnLastPart)}`;
      } else {
        acc = `${acc}${part[0].toUpperCase()}`;
      }
      return acc;
    }, '');
  }

  return name.substring(0, length);
};

/**
 * Assign a BEM class name with his modifiers
 * @param {string} base The base BEM className
 * @param {Object} modifiersMap An object who each keys will be used as modifier part name if value is true
 * @param {String|void} customClassName Prepend an other custom className on the result
 */
export const getBEMModifiers = (
  base: string,
  modifiersMap?: { [key: string]: boolean } = {},
  customClassName: string = ''
): string => {
  return getModifiers(base, modifiersMap, `${!!customClassName ? `${customClassName} ` : ''}${base}`);
};

/**
 *
 * @param {*} base
 * @param {*} modifiersMap
 * @param {String|void} startValue other className
 */
export const getModifiers = (
  base: string,
  modifiersMap?: { [key: string]: boolean } = {},
  startValue: string = '',
  standalone?: boolean = false
): string => {
  return objectEntries(modifiersMap).reduce((acc, [modifier, condition]) => {
    if (condition) {
      const modifierClassName = standalone ? modifier : `${base}--${modifier}`;
      return `${acc} ${modifierClassName}`;
    }
    return acc;
  }, startValue);
};

export const withDefaultModifierIfAllFalsy = (
  modifiersMap: { [key: string]: boolean },
  ifAllFalsyKey: string = 'default'
): { [key: string]: boolean } => {
  if (objectValues(modifiersMap).every((bool) => !!bool === false)) {
    return { [ifAllFalsyKey]: true };
  }
  return modifiersMap;
};

/**
 * @param {*} elementName The name of sub element
 * @param  {...any} bases All class names to prepends element name
 */
export const getBEMElements = (elementName: string, ...bases: Array<string | null | void>): string => {
  return filterMaybeElementCollection<string>(bases)
    .map((base: string) => `${base}__${elementName}`)
    .join(' ');
};

/**
 * Concat classNames
 * @param  {...String} classNames
 */
export const concatClassNames = (...classNames: Array<string | null | void>): string => {
  return filterMaybeElementCollection<string>(classNames).join(' ');
};

export const conditionnalClassNames = (
  condition: boolean,
  ...classNames: Array<string | null | void>
): string | void => {
  return condition ? concatClassNames(...classNames) : undefined;
};

export const concatStringWithSpace = concatClassNames;

export const getStandaloneModifiersClassNames = (
  modifiersMap: { [key: string]: boolean },
  otherClassNames: string = ''
): string => {
  return getModifiers('', modifiersMap, otherClassNames, true);
};

const formatWithArray = (resource: string, values: Array<string>): string => {
  return values.reduce((result: string, value: string, index: number) => {
    return result.replace(`{${index}}`, `${value}`);
  }, resource);
};

const formatWithObject = (resource: string, values: { [key: string]: any }): string => {
  return objectEntries(values).reduce((result: string, entry: [string, any], index: number) => {
    if (entry != null && entry.length === 2) {
      return result.replace(`{${entry[0]}}`, `${entry[1]}`);
    }
    return result;
  }, resource);
};

export const format = (resource: string, ...values: Array<mixed>): string => {
  if (values.length === 1 && typeof values[0] === 'object') {
    return formatWithObject(resource, (values[0]: any));
  }

  return formatWithArray(resource, (values: any));
};

export const isEmail = (value: string): boolean => {
  return !!value.match(
    new RegExp(
      // http://emailregex.com/ - JavaScript
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    )
  );
};

export const join = (values: Array<string>, separator: string, lastSeparator?: string): string => {
  if (values.length === 0) {
    return '';
  }

  if (values.length === 1) {
    return values[0];
  }

  const _getSeparator = (index: number): string => {
    return index === values.length - 1 ? lastSeparator || separator : separator;
  };

  const firstValue: string = values[0];
  const rest: Array<string> = values.slice(1);

  return rest.reduce((joined: string, current: string, i: number): string => {
    return [joined, current].join(_getSeparator(i + 1));
  }, firstValue);
};

const ONLY_NUMERICAL_CONTENT_REGEX = /^[0-9]+$/g;

export const hasOnlyNumbers = (stringToTest: string): boolean => {
  return stringToTest.search(ONLY_NUMERICAL_CONTENT_REGEX) !== -1;
};

export const isBlank = (text: string): boolean => {
  return /^\s*$/.test(text);
};

export const htmlStrong = (text: string): string => {
  return `<strong>${text}</strong>`;
};

export const htmlFirstWordStrong = (text: string): string => {
  const [firstWord, ...otherWords] = text.split(' ');
  return [htmlStrong(firstWord), ...otherWords].join(' ');
};

// Inspiré du travail de Mark Byers
// https://stackoverflow.com/questions/3008718/split-string-into-smaller-strings-by-length-variable
export const splitByLength = (value: string, length: number): Array<string> => {
  if (value.length <= length) {
    return [value];
  }

  const result: Array<string> = [];

  for (let i = 0; i < value.length; i += length) {
    result.push(value.substr(i, length));
  }

  return result;
};

export const splitSentence = (sentence: string, lengthOfParts: number): Array<string> => {
  if (sentence.length === 0) {
    return [];
  }

  if (sentence.length <= lengthOfParts) {
    return [sentence];
  }

  const parts: Array<string> = [''];
  const words: Array<string> = sentence.split(/\s+/);

  let currentPartIndex: number = 0;

  for (const word of words) {
    const currentPartLength: number = parts[currentPartIndex].length;

    if (currentPartLength + word.length + 1 > lengthOfParts) {
      parts[currentPartIndex] = parts[currentPartIndex].trimEnd();
      currentPartIndex++;
      parts[currentPartIndex] = '';
    }

    parts[currentPartIndex] += `${word} `;
  }

  return parts;
};
