// @flow

export const isNodeEnvProduction = (): boolean => {
  return process.env.NODE_ENV === 'production';
};

export const isNodeEnvDevelopment = (): boolean => {
  return process.env.NODE_ENV === 'development';
};

export const isNodeEnvTest = (): boolean => {
  return process.env.NODE_ENV === 'test';
};
