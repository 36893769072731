// @flow

import { type ConfigurationServiceAppPreloader } from 'src/core/configuration/ConfigurationServiceAppPreloader';
import { type ConfigurationProvider } from 'src/configuration/ConfigurationProvider';

import { setInstance, ConfigurationService } from 'src/configuration/ConfigurationService';
import { DefaultConfigurationProvider } from 'src/configuration/DefaultConfigurationProvider';

export class BaseConfigurationServiceAppPreloader implements ConfigurationServiceAppPreloader {
  configurationService(): () => ConfigurationService {
    return () => new ConfigurationService(this.configurationProviders());
  }

  configurationProviders(): () => Array<ConfigurationProvider> {
    return () => [new DefaultConfigurationProvider()];
  }

  initialize(): void {
    const configurationService = this.configurationService()();

    configurationService.initialize();
    setInstance(configurationService);
  }

  name(): string {
    return this.constructor.name;
  }

  priority(): number {
    return 0;
  }

  isDisabled(): boolean {
    return false;
  }
}
