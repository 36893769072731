// @flow
import { type Preloadable } from 'src/core/preloader/Preloadable';

export const DEFAULT_REQUIRED_PROCESS_ENV_VARS: Array<string> = [
  // Node (built-in)
  'NODE_ENV',

  // Create React App (built-in)
  'PUBLIC_URL',

  // Custom
  'REACT_APP_VERSION',
  'REACT_APP_NAME'
];

export class RequiredProcessEnvVarsValidator implements Preloadable {
  _requiredProcessEnvVars: Array<string>;

  constructor(requiredProcessEnvVars: Array<string> = DEFAULT_REQUIRED_PROCESS_ENV_VARS) {
    this._requiredProcessEnvVars = requiredProcessEnvVars;
  }

  name(): string {
    return this.constructor.name;
  }

  priority(): number {
    return 10;
  }

  isDisabled(): boolean {
    return false;
  }

  initialize(): void {
    // prettier-ignore
    const missingRequiredEnvVars = this._requiredProcessEnvVars
    .map(key => [key, process.env[key]])
    .filter(([key, value]) => value == null);

    if (missingRequiredEnvVars.length > 0) {
      const errorMessage: string = `Some required environment variables are missings:
      ${missingRequiredEnvVars.join(',\n')}`;
      throw new Error(errorMessage);
    }
  }
}
