// @flow
import * as React from 'react';

export const Spinner = (): React.Node => {
  return (
    <span className="spinner">
      <span className="spinner__bounce spinner__bounce--1" />
      <span className="spinner__bounce spinner__bounce--2" />
      <span className="spinner__bounce spinner__bounce--3" />
    </span>
  );
};
