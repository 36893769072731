// @flow

import { type ConfigurationProvider } from 'src/configuration/ConfigurationProvider';
import { type Environment } from 'src/configuration/Environment';

export const DEFAULT_CONFIGURATION_PROVIDER_NAME = 'DEFAULT';
export const DEFAULT_CONFIGURATION_PROVIDER_PRIORITY = 1;

export class DefaultConfigurationProvider implements ConfigurationProvider {
  name(): string {
    return DEFAULT_CONFIGURATION_PROVIDER_NAME;
  }

  priority(): number {
    return DEFAULT_CONFIGURATION_PROVIDER_PRIORITY;
  }

  configurations(): { [Environment]: { [key: string]: any } } {
    return {
      DEV: {
        'logger.consoleLogAppender.enabled': false
      }
    };
  }
}
