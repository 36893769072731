// @flow
import * as React from 'react';
import { getBEMModifiers } from 'src/utils/stringUtils';

import { Spinner } from 'src/modules/common/loading/Spinner';

type LoaderProps = {|
  field?: boolean,
  card?: boolean,
  group?: boolean,
  withBg?: boolean
|};

export const Loader = ({ field = false, card = false, group = false, withBg = true }: LoaderProps): React.Node => {
  return (
    <div className={getBEMModifiers('loadingContainer__background', { card, group, field, withBg })}>
      <span className="loadingContainer__loader">
        {' '}
        <Spinner />{' '}
      </span>
    </div>
  );
};
