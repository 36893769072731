// @flow

import { type ConfigurationProvider } from 'src/configuration/ConfigurationProvider';
import { type Environment } from 'src/configuration/Environment';

export const CONFIGURATION_WEBSOCKET_ENABLED = 'websocket.enabled';

export class RealtimeConfigurationProvider implements ConfigurationProvider {
  name(): string {
    return 'REALTIME';
  }

  priority(): number {
    return 1001;
  }

  configurations(): { [Environment]: { [key: string]: any } } {
    return {
      PROD: {
        [CONFIGURATION_WEBSOCKET_ENABLED]: false
      },
      PREVIEW: {
        [CONFIGURATION_WEBSOCKET_ENABLED]: false
      },
      TEST: {
        [CONFIGURATION_WEBSOCKET_ENABLED]: false
      },
      DEV: {
        [CONFIGURATION_WEBSOCKET_ENABLED]: true
      }
    };
  }
}
