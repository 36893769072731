// @flow
import isEqual from 'lodash/isEqual';
import isEmpty from 'lodash/isEmpty';
import isPlainObject from 'lodash/isPlainObject';

import type { FetchActionRequest } from 'src/generated/FetchAsyncActionService';
import { Logger } from 'src/utils/logger/Logger';

//Hack import
export const OBJECT_UTILS = 'objectUtils';

const isSelectedKey = (selectedKeys: Array<string> | null | void, itemKey: string): boolean => {
  return !selectedKeys || selectedKeys.includes(itemKey);
};

/**
 * Return a new object that auto map all new/changed properties value between oldData and newData
 * @param {*} newData
 * @param {*} oldData
 * @param {String[]} selectedKeys An array of keyName that can be mapped
 */
export const deltaMapObject = (newData: any, oldData: any, selectedKeys?: Array<any>): any => {
  const deltaMappedObject: any = {};
  objectEntries(newData).forEach(itemArray => {
    const itemKey = itemArray[0];
    const itemValue = itemArray[1];
    if (!isEqual(itemValue, oldData[itemKey]) && isSelectedKey(selectedKeys, itemKey)) {
      deltaMappedObject[itemKey] = itemValue;
    }
  });

  return deltaMappedObject;
};

export const objectTransformUndefinedToNull = <T: { [key: string]: any }>(object: T): T => {
  const acc = (({}: any): T);
  return objectEntries(object).reduce((result, entry) => {
    const [key, value] = entry;
    result[key] = value === undefined ? null : value;
    return result;
  }, acc);
};

export const objectTransformNullToUndefined = <T: { [key: string]: any }>(
  object: T,
  selectedKeys?: Array<string>
): T => {
  return objectTransformNullToValue(object, undefined, selectedKeys);
};

export const objectTransformNullToEmptyString = <T: { [key: string]: any }>(
  object: T,
  selectedKeys?: Array<string>
): T => {
  return objectTransformNullToValue(object, '', selectedKeys);
};

export const objectTransformNullToZero = <T: { [key: string]: any }>(object: T, selectedKeys?: Array<string>): T => {
  return objectTransformNullToValue(object, 0, selectedKeys);
};

export const objectTransformNullToValue = <T: { [key: string]: any }>(
  object: T,
  value: any,
  selectedKeys?: Array<string>
): T => {
  const keysToTransform: Array<string> = selectedKeys || Object.keys(object); // If no selectedKeys, transform all
  const newObject = { ...object };
  Object.entries(object).forEach(itemArray => {
    if (itemArray[1] === null && keysToTransform.includes(itemArray[0])) {
      newObject[itemArray[0]] = value;
    }
  });
  return newObject;
};

export const setValueAtKeyIfNotEmpty = (key: string, value: any): any => {
  return isEmpty(value) ? {} : { [key]: value };
};

/**
 *
 * @param {*} rawRequest The raw request oject
 * @param {*} paramKey Key to find
 * @param {*} logger Optional
 */
export const getPathParamFromRawRequest = function(
  rawRequest: FetchActionRequest<any>,
  paramKey: string,
  logger?: Logger
): string {
  if (rawRequest.pathParams && rawRequest.pathParams[paramKey]) {
    return rawRequest.pathParams[paramKey];
  } else {
    logger && logger.error(`PathParam key "${paramKey}" not found on rawRequest`, rawRequest);
    throw new Error(`PathParam key "${paramKey}" not found on rawRequest`);
  }
};

/**
 * Make a customizer for lodash isEqualWidth who ignore given keys
 */
export const isGetEqualIgnoreKeyCustomizer = (keys: Array<string>): ((any, any, number | string) => boolean | void) => (
  _objValue: any,
  _othValue: any,
  indexKey: number | string
): boolean | void => {
  if (keys.some(key => key === indexKey)) {
    return true;
  }
  return undefined;
};

export const getIsShallowEqualObjectCustomizer = (
  keyId: string = 'id'
): ((any, any, number | string) => boolean | void) => (
  _objValue: any,
  _othValue: any,
  indexKey: number | string
): boolean | void => {
  if (!!_objValue && !!_othValue && _objValue.hasOwnProperty(keyId) && _othValue.hasOwnProperty(keyId)) {
    return _objValue[keyId] === _othValue[keyId];
  }
  return undefined;
};

export const getValueOrReturnValue = <R: any>(valueGetter: R | (() => R)): R => {
  return typeof valueGetter === 'function' ? valueGetter() : valueGetter;
};

export type Entry<K: string | number, V: any> = [K, V];
export type ObjectEntry<T: { [key: string]: any }> = [$Keys<T>, $Values<T>];

export const entriesToObject = <T: any>(keyValuePairs: Array<[string, T]>): { [key: string]: T } => {
  return keyValuePairs.reduce((acc: { [key: string]: T }, [key, value]) => {
    acc[key] = value;
    return acc;
  }, {});
};

export const objectEntries = <T: Object>(obj: T): Array<Entry<$Keys<T>, any>> => {
  return Object.entries(obj);
};

export const objectValues = <T: Object>(obj: T): Array<any> => {
  return Object.values(obj);
};

export const objectKeys = <T: Object>(obj: T): Array<$Keys<T>> => {
  return Object.keys(obj);
};

export const objectAssign = <T>(object: T): T => {
  return Object.assign({}, object);
};

// TODO rename to isAllObjectValuesUndefined
// eslint-disable-next-line no-unused-vars
export const isAllUndefinedModelContent = <M: any>(Model: M): boolean => {
  return Object.values(Model).every(value => value === undefined);
};

/**
  Returns all field paths in the specified object

  getObjectFieldsPaths({
    a: 1,
    b: {
      c: 2,
      d: {
        c: {
          y: 24,
          e: {
            f: 1
          }
        },
        d: 3
      }
    }
  }, '');

  RESULT -> ["a", "b.c", "b.d.c.y", "b.d.c.e.f", "b.d.d"]

  @param {{[string]: any}} object - A javascript object
  @param {string} path - base path (optional)

  @return {Array<string>} Specified object fields paths
 */
export const getObjectFieldsPaths = (
  object: { [key: string]: any },
  path: string = '',
  ignoredKeys: Array<string> = [],
  ignoreUndefined: boolean = true,
  ignoreNull: boolean = false
): Array<string> => {
  const createPaths = (
    object: { [key: string]: any } | string | number | boolean | Date | Array<any> | null | void,
    path: string
  ): Array<string> => {
    const paths = [];

    if (object === undefined) {
      return paths;
    }

    if (
      typeof object === 'string' ||
      typeof object === 'number' ||
      typeof object === 'boolean' ||
      object === null ||
      object instanceof Date
    ) {
      paths.push(path);
      return paths;
    }

    if (Array.isArray(object)) {
      object.forEach((subValue: any, index: number) => {
        paths.push(...createPaths(subValue, combinePath(path, index)));
      });
      return paths;
    }

    objectEntries(object).forEach(entry => {
      const [key, value] = entry;
      const valueObjectPath = combinePath(path, key);

      if (ignoreUndefined && value === undefined) {
        return;
      }

      if (ignoreNull && value === null) {
        return;
      }

      if (isPlainObject(value)) {
        paths.push(...createPaths(value, valueObjectPath));
      } else if (Array.isArray(value)) {
        value.forEach((subValue: any, index: number) => {
          if (ignoreUndefined && subValue === undefined) {
            return;
          }

          if (ignoreNull && subValue === null) {
            return;
          }

          paths.push(...createPaths(subValue, combinePath(valueObjectPath, index)));
        });
      } else if (!ignoredKeys.includes(key)) {
        paths.push(valueObjectPath);
      }
    });

    return paths;
  };

  return createPaths({ ...object }, path);
};

const combinePath = (...names: Array<string | number | null | void>): string => {
  return names.filter(name => name !== '').join('.');
};

export const copyWithout = (object: { [key: string]: any }, without: Array<string>): { [key: string]: any } => {
  return objectEntries(object).reduce((result: { [key: string]: any }, currentEntry) => {
    const [key, value] = currentEntry;
    if (!without.includes(key)) {
      result[key] = value;
    }
    return result;
  }, {});
};

export const requireNonNil = <T: any>(value: T | null | void, message?: string): T => {
  if (value == null) {
    throw new ReferenceError(message);
  }
  return value;
};

export const requireNonEmpty = <T: any>(value: T | null | void, message?: string): T => {
  if (value == null || isEmpty(value)) {
    throw new ReferenceError(message);
  }
  return value;
};
