//@flow
import * as React from 'react';
// eslint-disable-next-line camelcase
import { unstable_batchedUpdates } from 'react-dom';

import { addClassName } from 'src/utils/domUtils';
import { removeClassName } from 'src/utils/domUtils';
import { type ProgressObserver } from 'src/modules/apiUtils/ProgressObserver';

type Props = {|
  observer: ProgressObserver,
  mountProgress?: number,
  mountIsDone?: boolean
|};
export const ProgressLoading = ({ observer, mountIsDone = true, mountProgress = 100 }: Props): React.Node => {
  const [isDone, setIsDone] = React.useState<boolean>(mountIsDone);
  const [progress, setProgress] = React.useState<number>(mountProgress);
  const $div = React.useRef<HTMLDivElement | null>(null);
  const widthPercent = Math.max(5, progress);

  const style = React.useMemo(() => ({ width: `${widthPercent}%` }), [widthPercent]);

  const update = React.useCallback((newProgress: number, isNowDone: boolean) => {
    // https://dev.to/raibima/batch-your-react-updates-120b
    unstable_batchedUpdates(() => {
      setIsDone(isNowDone);
      setProgress(newProgress);
    });
  }, []);

  React.useEffect(() => {
    const subscribeIndex = observer.subscribe(update);
    return () => {
      observer.unsubscribe(subscribeIndex);
    };
  }, [observer, update]);

  React.useLayoutEffect(() => {
    const $current = $div.current;
    if (!isDone) {
      addClassName(document.body, 'cursor--progress');
      addClassName($current, 'ProgressLoading--visible');
    } else {
      removeClassName(document.body, 'cursor--progress');
      removeClassName($current, 'ProgressLoading--visible');
    }
    return () => {
      removeClassName(document.body, 'cursor--progress');
      removeClassName($current, 'ProgressLoading--visible');
    };
  }, [isDone]);

  return (
    <div className="ProgressLoading" ref={$div}>
      <div className="ProgressLoading__value" style={style}></div>
    </div>
  );
};
