// @flow
import { type Preloadable } from 'src/core/preloader/Preloadable';

export class InteractionObserverPolyfillAppPreloader implements Preloadable {
  initialize(): void {
    require('intersection-observer');
  }

  name(): string {
    return this.constructor.name;
  }

  priority(): number {
    return 10;
  }

  isDisabled(): boolean {
    const hasInteractionObserver: boolean =
      'IntersectionObserver' in window &&
      'IntersectionObserverEntry' in window &&
      'intersectionRatio' in window.IntersectionObserverEntry.prototype;
    return hasInteractionObserver;
  }
}
