// @flow

import { type Preloadable } from 'src/core/preloader/Preloadable';
import { isNodeEnvDevelopment, isNodeEnvTest } from 'src/core/configuration/NodeEnvUtils';

export const preload = (preloaders: Array<Preloadable>): void => {
  preloaders
    .filter((preloader: Preloadable): boolean => !preloader.isDisabled())
    .sort((a: Preloadable, b: Preloadable): number => b.priority() - a.priority())
    .forEach((preloader: Preloadable) => {
      if (isNodeEnvDevelopment() || isNodeEnvTest()) {
        // eslint-disable-next-line no-console
        console.log(`preloading ${preloader.name()} ...`);
      }
      preloader.initialize();
    });
};
