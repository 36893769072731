// @flow

import { BaseLogAppender } from 'src/utils/logger/LogAppender';
import { type LogEntry } from 'src/utils/logger/LogEntry';

const DEFAULT_MAX_LENGTH: number = 1000;

export class InMemoryLogAppender extends BaseLogAppender {
  maxLength: number = DEFAULT_MAX_LENGTH; // TODO use configuration
  logs: Array<LogEntry> = [];

  trace(logEntry: LogEntry): void {
    this._pushLog(logEntry);
  }

  debug(logEntry: LogEntry): void {
    this._pushLog(logEntry);
  }

  info(logEntry: LogEntry): void {
    this._pushLog(logEntry);
  }

  warn(logEntry: LogEntry): void {
    this._pushLog(logEntry);
  }

  error(logEntry: LogEntry): void {
    this._pushLog(logEntry);
  }

  _pushLog(logEntry: LogEntry): void {
    if (this.logs.length >= this.maxLength) {
      this.logs.shift();
    }
    this.logs.push(logEntry);
  }
}
