// @flow
import { isEmpty as _isEmpty } from 'lodash';

export const nonNil = (value: mixed): boolean => {
  return value != null;
};

export const nonEmpty = (value: mixed): boolean => {
  return !_isEmpty(value);
};

export const isFalsy = (value: mixed): boolean => {
  return !value;
};

export const isTruthy = (value: mixed): boolean => {
  return !!value;
};

export const nonZero = (value: number): boolean => {
  return value !== 0;
};
