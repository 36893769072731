// @flow
import frCA from 'date-fns/locale/fr-CA';

import * as DateFNS from 'date-fns';
import * as DateFNSFunctional from 'date-fns/fp';
import { type Interval } from 'date-fns';
import { type Locale } from 'date-fns';

import { loggerFactory } from 'src/utils/logger/LoggerFactory';
import { nonNil } from 'src/utils/StreamUtils';

const logger = loggerFactory.getLogger('DateUtils_datefns');

export const INCEPTION_YEAR = 2019;

export const INCEPTION_COTISATION_YEAR = 2023;

export const YEAR_MONTH_DAY_FORMAT = 'yyyy-MM-dd';
export const YEAR_MONTH_DAY_HUMAN_FORMAT = 'd MMMM yyyy';
export const MONTH_DAY_HUMAN_FORMAT = 'd MMMM';
export const TIME_FORMAT = 'HH:mm';
export const TIME_FORMAT_WITH_SECOND = 'HH:mm:ss';
export const TIME_HUMAN_FORMAT = "HH'h'mm";
export const DATE_TIME_FORMAT = `${YEAR_MONTH_DAY_FORMAT} ${TIME_FORMAT}`;
export const DATE_TIME_HUMAN_FORMAT = `${YEAR_MONTH_DAY_FORMAT} ${TIME_HUMAN_FORMAT}`;
export const DATE_TIME_HUMAN_FORMAT_PARENTHESE = `${YEAR_MONTH_DAY_FORMAT} (${TIME_HUMAN_FORMAT})`;
export const LONG_DATE_TIME_HUMAN_FORMAT = `${YEAR_MONTH_DAY_HUMAN_FORMAT} 'à' ${TIME_HUMAN_FORMAT}`;
export const SHORT_WEEKDAY_DAY_HUMAN_FORMAT = 'E dd';
export const SHORT_WEEKDAY_DAY_HUMAN_FORMAT_NBSP = 'E\u00a0dd';
export const HUMAN_READABLE_DATE = 'eeee dd MMMM';
export const HUMAN_READABLE_DATETIME = `${TIME_HUMAN_FORMAT} - ${HUMAN_READABLE_DATE}`;

export const MAX_DATE_LOCAL: Date = new Date(2200, 8, 12, 20);

export const DEFAULT_LOCALE = frCA;
const DEFAULT_FORMAT_OPTIONS = {
  locale: DEFAULT_LOCALE
};

type FormatDurationOptions = {|
  format?: string[],
  zero?: boolean,
  delimiter?: string,
  locale?: Locale
|};

class _DateUtils {
  now(): Date {
    return new Date();
  }
}

export const DateUtils: _DateUtils = new _DateUtils();

export const noon = (date?: Date = DateUtils.now()): Date => {
  const noon: Date = new Date(date.getTime());
  return DateFNS.set(noon, { hours: 12, minutes: 0, seconds: 0, milliseconds: 0 });
};

export const dateWithTime = (date: Date, time: string, format: string): Date => {
  return DateFNS.parse(time, format, date);
};

export const nowWithTime = (time: string, format: string = TIME_FORMAT_WITH_SECOND): Date => {
  return dateWithTime(DateUtils.now(), time, format);
};

const hasTime = (date: string): boolean => {
  return date.indexOf('T') !== -1;
};

const formatFn: (outputFormat: string, date: Date | number) => string = (DateFNSFunctional.formatWithOptions(
  DEFAULT_FORMAT_OPTIONS
): any);

export const format = (date: Date | number, outputFormat: string): string => {
  return formatFn(outputFormat, date);
};

export const formatDuration = (duration: DateFNS.Duration, options: FormatDurationOptions): string => {
  return DateFNS.formatDuration(duration, { ...DEFAULT_FORMAT_OPTIONS, ...options });
};

const formatWithMidnightTime = (date: string): string => {
  return `${date}T00:00`;
};

export const stringToDate = (date: string, defaultIfInvalid: Date = new Date()): Date => {
  const newDate: Date = hasTime(date) ? new Date(date) : new Date(formatWithMidnightTime(date));
  return DateFNS.isValid(newDate) ? newDate : defaultIfInvalid;
};

/**
 * For available formatting patterns, see https://date-fns.org/v2.15.0/docs/format
 */
export const dateToString = (
  date: Date | number | null | void,
  outputFormat: string = YEAR_MONTH_DAY_FORMAT
): string | null => {
  if (date === null || date === undefined) {
    return null;
  }

  return format(date, outputFormat);
};

export const dateToStringTime = (date: Date | number | null | void): string | null => {
  return dateToString(date, TIME_FORMAT);
};

export const nowToString = (outputFormat: string = YEAR_MONTH_DAY_FORMAT): string => {
  return format(DateUtils.now(), outputFormat);
};

export const areIntervalsOverlapping = (
  firstIntervalStart: Date | null | void,
  firstIntervalEnd: Date | null | void,
  secondIntervalStart: Date | null | void,
  secondIntervalEnd: Date | null | void,
  inclusive: boolean = false
): boolean => {
  if (
    firstIntervalStart == null ||
    firstIntervalEnd == null ||
    secondIntervalStart == null ||
    secondIntervalEnd == null
  ) {
    return false;
  }

  const firstInterval: DateFNS.Interval = { start: firstIntervalStart, end: firstIntervalEnd };
  const secondInterval: DateFNS.Interval = { start: secondIntervalStart, end: secondIntervalEnd };

  try {
    return DateFNS.areIntervalsOverlapping(firstInterval, secondInterval, { inclusive });
  } catch (error) {
    logger.error(error, firstInterval, secondInterval);
  }

  return false;
};

type IntervalPropsNullable = {
  start: $PropertyType<Interval, 'start'> | null,
  end: $PropertyType<Interval, 'end'> | null,
  ...
};

export const isWithinInterval = (
  date: Date | number | null,
  intervalPropsNullable: $ReadOnly<Partial<IntervalPropsNullable>>
): boolean => {
  const { start, end } = intervalPropsNullable;
  if (date === null || start == null || end == null) {
    return false;
  }

  try {
    return DateFNS.isWithinInterval(date, { start, end });
  } catch (error) {
    logger.error(error, date, start, end);
    return false;
  }
};

export const getStartOfDay = (date: Date | number | null | void): Date | null => {
  if (date == null) {
    return null;
  }
  try {
    return DateFNS.startOfDay(date);
  } catch (error) {
    logger.error(error, date);
    return null;
  }
};

export const getStartOfToday = (): Date => {
  return DateFNS.startOfDay(DateUtils.now());
};

export const getEndOfDay = (date: Date | number | null | void): Date | null => {
  if (date == null) {
    return null;
  }
  try {
    return DateFNS.endOfDay(date);
  } catch (error) {
    logger.error(error, date);
    return null;
  }
};

export const getMiddleOfDay = (date: Date | number | null | void): Date | null => {
  if (date == null) {
    return null;
  }
  try {
    return DateFNS.startOfHour(DateFNS.setHours(date, 12));
  } catch (error) {
    logger.error(error, date);
    return null;
  }
};

export const isStartOfDay = (date: Date | number | null): boolean => {
  if (date === null) {
    return false;
  }

  try {
    return DateFNS.isEqual(date, DateFNS.startOfDay(date));
  } catch (error) {
    logger.error(error, date);
    return false;
  }
};

export const flatDateAfterMinutes = (date: Date | null): Date | null => {
  if (date === null) {
    return null;
  }

  return DateFNS.set(date, { seconds: 0, milliseconds: 0 });
};

export const startOfCurrentDay = (): Date => {
  return DateFNS.startOfDay(DateUtils.now());
};

export const addMilliseconds = (date: Date | number | null | void, milliseconds: number): Date | null => {
  if (date == null) {
    return null;
  }

  return DateFNS.addMilliseconds(date, milliseconds);
};

export const addHours = (date: Date | number | null | void, nbHours: number): Date | null => {
  if (date == null) {
    return null;
  }

  return DateFNS.addHours(date, nbHours);
};

export const subHours = (date: Date | number | null | void, nbHours: number): Date | null => {
  if (date == null) {
    return null;
  }

  return DateFNS.subHours(date, nbHours);
};

export const subMilliseconds = (date: Date | number | null | void, milliseconds: number): Date | null => {
  if (date == null) {
    return null;
  }

  return DateFNS.subMilliseconds(date, milliseconds);
};

export const addOneMillisecond = (date: Date | null): Date | null => {
  return addMilliseconds(date, 1);
};

export const subOneMillisecond = (date: Date | null): Date | null => {
  return subMilliseconds(date, 1);
};

export const addDays = (date: Date | number | null | void, days: number): Date | null => {
  if (date == null) {
    return null;
  }

  return DateFNS.addDays(date, days);
};

export const subDays = (date: Date | number | null | void, days: number): Date | null => {
  if (date == null) {
    return null;
  }

  return DateFNS.subDays(date, days);
};

export const addYears = (date: Date | number | null | void, years: number): Date | null => {
  if (date == null) {
    return null;
  }

  return DateFNS.addYears(date, years);
};

export const addOneDay = (date: Date | number | null | void): Date | null => {
  return addDays(date, 1);
};

export const subOneDay = (date: Date | number | null | void): Date | null => {
  return subDays(date, 1);
};

export const lastMomentOfGivenDayPeriod = (date: Date): Date => {
  return DateFNS.subMilliseconds(DateFNS.addDays(date, 1), 1);
};

export const isBefore = (date: Date | number | null | void, dateToCompare: Date | number | null | void): boolean => {
  if (date == null || dateToCompare == null) {
    return false;
  }
  try {
    return DateFNS.isBefore(date, dateToCompare);
  } catch (error) {
    logger.error(error, date, dateToCompare);
    return false;
  }
};

export const isEqual = (dateOne: Date | number | null | void, dateTow: Date | number | null | void): boolean => {
  if (dateOne == null || dateTow == null) {
    return false;
  }
  try {
    return DateFNS.isEqual(dateOne, dateTow);
  } catch (error) {
    logger.error(error, dateOne, dateTow);
    return false;
  }
};

export const isNotEqual = (dateOne: Date | number | null | void, dateTow: Date | number | null | void): boolean => {
  if (dateOne == null || dateTow == null) {
    return false;
  }
  try {
    return !DateFNS.isEqual(dateOne, dateTow);
  } catch (error) {
    logger.error(error, dateOne, dateTow);
    return false;
  }
};

export const isAfter = (date: Date | number | null | void, dateToCompare: Date | number | null | void): boolean => {
  if (date == null || dateToCompare == null) {
    return false;
  }
  try {
    return DateFNS.isAfter(date, dateToCompare);
  } catch (error) {
    logger.error(error, date, dateToCompare);
    return false;
  }
};

export const isFuture = (date: Date | number | null | void): boolean => {
  if (date == null) {
    return false;
  }
  try {
    return DateFNS.isFuture(date);
  } catch (error) {
    logger.error(error, date);
    return false;
  }
};

export const isToday = (date: Date | number | null | void): boolean => {
  if (date == null) {
    return false;
  }
  try {
    return DateFNS.isToday(date);
  } catch (error) {
    logger.error(error, date);
    return false;
  }
};

export const isTomorrow = (date: Date | number | null | void): boolean => {
  if (date == null) {
    return false;
  }
  try {
    return DateFNS.isTomorrow(date);
  } catch (error) {
    logger.error(error, date);
    return false;
  }
};

export const isSameTime = (first: Date | null | void, second: Date | null | void): boolean => {
  if (!!first && !!second) {
    return first.getTime() === second.getTime();
  }
  return first === second;
};

export const differenceInMilliseconds = (
  first: Date | number | null | void,
  second: Date | number | null | void
): number => {
  if (first == null || second == null) {
    return 0;
  }

  try {
    return DateFNS.differenceInMilliseconds(first, second);
  } catch (error) {
    logger.error(error, first, second);
    return 0;
  }
};

type DistanceFromNowOptions = {|
  includeSeconds?: boolean,
  addSuffix?: boolean,
  locale?: Locale
|};

export const formatDistanceToNow = (
  date: Date | number | null | void,
  options?: DistanceFromNowOptions
): string | null => {
  if (date == null) {
    return null;
  }

  return DateFNS.formatDistanceToNow(date, { ...DEFAULT_FORMAT_OPTIONS, ...options });
};

type NumberOfDaysOfIntervalConfig = {|
  excludeWeekends?: boolean,
  includeStart?: boolean
|};

export const getIntervalDaysCount = (interval: Interval, config: NumberOfDaysOfIntervalConfig): number => {
  const { excludeWeekends = false, includeStart = false } = config;
  const daysOfInterval: Array<Date> = DateFNS.eachDayOfInterval(interval);

  const numberOfOpenDays: number = daysOfInterval.reduce((acc: number, current: Date, index: number) => {
    if (excludeWeekends && DateFNS.isWeekend(current)) {
      return acc;
    }
    if (!includeStart && index === 0) {
      return acc;
    }
    return acc + 1;
  }, 0);

  return numberOfOpenDays;
};

export const min = (...dates: Array<Date | number | null | void>): Date | null => {
  if (dates.length === 0) {
    return null;
  }

  // $FlowExpectedError - flow doesn't recognize filtered types
  const safeDates: Array<Date | number> = dates.filter(nonNil);
  return DateFNS.min(safeDates);
};

export const max = (...dates: Array<Date | number | null | void>): Date | null => {
  if (dates.length === 0) {
    return null;
  }

  // $FlowExpectedError - flow doesn't recognize filtered types
  const safeDates: Array<Date | number> = dates.filter(nonNil);
  return DateFNS.max(safeDates);
};

export const getWithBeginTimeCourtier = (
  date: Date,
  time: string,
  format: 'HH:mm' | 'HH:mm:ss' | 'HH:mm:ss.SSS' = 'HH:mm:ss.SSS'
): Date => {
  return dateWithTime(date, time, format);
};

export const getWithEndTimeCourtier = (
  date: Date,
  time: string,
  format: 'HH:mm' | 'HH:mm:ss' | 'HH:mm:ss.SSS' = 'HH:mm:ss.SSS'
): Date => {
  return DateFNS.subMilliseconds(getWithBeginTimeCourtier(date, time, format), 1);
};

export const startOfInceptionYear = (): Date => {
  return DateFNS.startOfYear(DateFNS.setYear(DateUtils.now(), INCEPTION_YEAR));
};

export const getYearsSince = (startYear: number, inclusiveStart?: boolean = true): Array<number> => {
  const currentYear: number = new Date().getFullYear();
  const values: Array<number> = [];

  for (let i = inclusiveStart ? 0 : 1; i <= currentYear - startYear; i++) {
    values.push(startYear + i);
  }

  return values.map(Number);
};
