// @flow

import { type LogLevel } from 'src/utils/logger/LogLevel';
import { type Environment } from 'src/configuration/Environment';

import { ERROR, INFO, WARN, DEBUG } from 'src/utils/logger/LogLevel';
import { Environments_ } from 'src/configuration/Environment';

export type OwnAppVariables = {|
  ENVIRONMENT: Environment,
  API_URL: string,
  REALTIME_URL: string,
  PUBLIC_STATIC_URL: string,
  GOOGLE_MAPS_API: string,
  ROOT_LOGGER_LEVEL: LogLevel,
  INACTIVITY_TIMEOUT: string
|};

const OWN_APP_VARIABLES_PROD: OwnAppVariables = {
  ENVIRONMENT: 'PROD',
  API_URL: 'https://api.monancai.com',
  REALTIME_URL: 'wss://realtime.monancai.com/realtime',
  GOOGLE_MAPS_API: 'AIzaSyB8bEQ3Ztbbd5epCMHB_J4M-9JVcBGx-J8',
  PUBLIC_STATIC_URL: 'https://s3.ca-central-1.amazonaws.com/public-static-prod-monancai.com',
  ROOT_LOGGER_LEVEL: ERROR,
  INACTIVITY_TIMEOUT: '43200000' // 43 200 000ms === 12h
};

const OWN_APP_VARIABLES_PREVIEW: OwnAppVariables = {
  ...OWN_APP_VARIABLES_PROD,
  ENVIRONMENT: 'PREVIEW',
  API_URL: 'https://api-preview.monancai.com',
  REALTIME_URL: 'wss://realtime-preview.monancai.com/realtime',
  GOOGLE_MAPS_API: 'AIzaSyB8bEQ3Ztbbd5epCMHB_J4M-9JVcBGx-J8',
  PUBLIC_STATIC_URL: 'https://s3.ca-central-1.amazonaws.com/public-static-dev-monancai.com',
  ROOT_LOGGER_LEVEL: WARN
};

const OWN_APP_VARIABLES_DEV: OwnAppVariables = {
  ...OWN_APP_VARIABLES_PROD,
  ENVIRONMENT: 'DEV',
  API_URL: 'http://localhost:8080',
  REALTIME_URL: 'ws://localhost:8080/realtime',
  GOOGLE_MAPS_API: 'AIzaSyDLa7e7zoYJq4RUqurgGR9zXfTpN_LCy0w',
  PUBLIC_STATIC_URL: 'https://s3.ca-central-1.amazonaws.com/public-static-dev-monancai.com',
  ROOT_LOGGER_LEVEL: INFO
};

const OWN_APP_VARIABLES_TEST: OwnAppVariables = {
  ENVIRONMENT: 'TEST',
  API_URL: 'http://localhost:8080',
  REALTIME_URL: 'ws://localhost:8080/realtime',
  GOOGLE_MAPS_API: 'AIzaSyDLa7e7zoYJq4RUqurgGR9zXfTpN_LCy0w',
  PUBLIC_STATIC_URL: 'https://s3.ca-central-1.amazonaws.com/public-static-dev-monancai.com',
  ROOT_LOGGER_LEVEL: DEBUG,
  INACTIVITY_TIMEOUT: '43200000' // 43 200 000ms === 12h
};

export const OWN_APP_VARIABLES_MAP: { [key: Environment]: OwnAppVariables } = {
  [Environments_.TEST]: OWN_APP_VARIABLES_TEST,
  [Environments_.DEV]: OWN_APP_VARIABLES_DEV,
  [Environments_.PREVIEW]: OWN_APP_VARIABLES_PREVIEW,
  [Environments_.PROD]: OWN_APP_VARIABLES_PROD
};
