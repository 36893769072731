// @flow
import _defaultsDeep from 'lodash/defaultsDeep';
import { setVariable as setWindowVariable } from 'src/core/browser/WindowUtil';
import { type ConfigurationProvider } from 'src/configuration/ConfigurationProvider';
import { type Environment, ENVIRONMENT_PRIORITIES, environmentFromValue } from 'src/configuration/Environment';

import { getEnvironment } from 'src/configuration/ReactAppConfigurationProvider';
import { requireNonNil } from 'src/utils/ObjectUtils';
import { type Initializable } from 'src/core/Initializable';

import { Environments_ } from 'src/configuration/Environment';

export class ConfigurationService implements Initializable {
  _configurations: { [key: string]: any } = {};

  _configurationProviders: Array<ConfigurationProvider> = [];

  constructor(configurationProviders: () => Array<ConfigurationProvider>) {
    this._configurationProviders = configurationProviders();
  }

  initialize() {
    const currentEnvironment: Environment = requireNonNil(environmentFromValue(getEnvironment()));

    if (currentEnvironment !== Environments_.PROD) {
      setWindowVariable('configurationService', this);
    }

    const _extendConfigurationsByEnvironementPriority = (configurationMap: {
      [Environment]: { [key: string]: any }
    }): { [Environment]: { [key: string]: any } } => {
      return ENVIRONMENT_PRIORITIES.reduceRight(
        (
          configMap: { [key: Environment]: { [key: string]: any } },
          envName: Environment,
          index: number,
          array: Array<any>
        ) => {
          configMap[envName] = _defaultsDeep(
            configMap[envName],
            ...array.slice(0, index).map((supEnvName: Environment) => configMap[supEnvName])
          );
          return configMap;
        },
        configurationMap
      );
    };

    const providedConfigurations: { [key: Environment]: { [key: string]: any } } = this._configurationProviders
      .sort((a: ConfigurationProvider, b: ConfigurationProvider) => b.priority() - a.priority())
      .map((configurationProvider: ConfigurationProvider) => {
        // eslint-disable-next-line no-console
        console.log(`loading configuration ${configurationProvider.name()}`);
        return configurationProvider;
      })
      .map((configurationProvider: ConfigurationProvider) => configurationProvider.configurations())
      .map(_extendConfigurationsByEnvironementPriority)
      .reduce(
        (a: { [key: Environment]: { [key: string]: any } }, b: { [key: Environment]: { [key: string]: any } }) =>
          _defaultsDeep(a, b),
        {}
      );

    this._configurations = providedConfigurations[currentEnvironment];
  }

  get(key: string): any {
    return this._configurations[key];
  }
}

let _configurationService: ConfigurationService;

export const getInstance = (): ConfigurationService => {
  return _configurationService;
};

export const setInstance = (configurationService: ConfigurationService): void => {
  if (_configurationService != null) {
    throw new Error('configuration service is already set');
  }
  _configurationService = configurationService;
};
